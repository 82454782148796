<template lang="pug">
    .main-content.create-offer.layout-main__centering.container.pb-5
      insert-data(v-if="$route.params.step === 'insert'", :form='getAdData', :isChangeAd='isChangeAd', @next='confirm', @change="changeAd", is-edit)
      confirm-data(v-if="$route.params.step === 'confirm'", :form='form', :isChangeAd='isChangeAd', @confirm='publish', is-edit)
      done(v-if="$route.params.step === 'done'", :form='form', :isChangeAd='isChangeAd', @next='reset', is-edit)
</template>

<script>
  import {mapGetters} from 'vuex';
  import {AD} from '../store/actions/ad';
  import utilsMixin from '../components/mixins/utils';
  import InsertData from '../components/AdCreate/InsertData.vue';
  import ConfirmData from '../components/AdCreate/ConfirmData.vue';
  import Done from '../components/AdCreate/Done.vue';
  import { SOCKET_DASHBOARD_OFF, SOCKET_DASHBOARD } from '../store/actions/user';

  export default {
    components: {
      InsertData,
      ConfirmData,
      Done,
    },
    mixins: [
      utilsMixin,
    ],
    data() {
      return {
        isChangeAd: false,
        form: {},
      }
    },
    computed: {
      ...mapGetters([
        'getAd',
        'getProfile',
      ]),
      getAdData() {
        return this.getAd(this.id);
      },
      id() {
        return this.$route.params.id;
      },
    },
    watch: {
      adId: {
        handler: 'get',
        immediate: true,
      }
    },
    created() {
      this.$router.push({name: 'AdEdit', params: {step: 'insert'} }).catch(()=>{});
    },
    mounted() {
      this.$store.dispatch(SOCKET_DASHBOARD_OFF, { id: this.getProfile.id });
    },
    beforeDestroy() {
      this.$store.dispatch(SOCKET_DASHBOARD, { id: this.getProfile.id });
    },
    methods: {
      get() {
        this.$store.dispatch(AD, { id: this.id });
      },
      reset() {
        // TODO: clear
        this.form = this.defaultValues();
      },
      confirm(ad, labels) {
        this.form = ad;
      },
      publish(ad) {
        this.form = ad;
      },
      changeAd(status) {
        this.isChangeAd = status;
      },
    },
  }
</script>

<style lang="css">

  .create-offer--step {
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.148211);
    border-radius: 8px 8px 0 0;
    margin-top: 24px;
    padding: 30px;
    background: white;
  }

  .create-input__item {
    outline: none;
  }

</style>
